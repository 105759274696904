/**
 * Utility: Cookies
 *
 * @module utility/UCookies
 * @example import { UCookies } from '../common/utility/u-cookies';
 */


/**
 * Get a cookie value
 *
 * Non-existent cookies return undefined
 *
 * @param   {string}  name  The name of the cookie
 *
 * @return  {string|undefined}  Cookie value, or undefined if the cookie does not exist
 */
function getCookie( name )
{
	if ( !name )
	{
		return;
	}

	const getCookie = document.cookie.split( ';' ).filter( item => item.indexOf( name + '=' ) > -1 );

	if ( getCookie.length )
	{
		const cookieValue = getCookie[0].replace( /^[^=]*=/, '' ); // remove everything up to and including "="

		return ( cookieValue.length ) ? cookieValue : undefined;
	}
}

/**
 * Set a cookie
 *
 * Creates a new cookie, or updates an existing one
 *
 * @param  {string}  name        Cookie name (key)
 * @param  {mixed}   value       Cookie value. Accepts: string|number|boolean. Default: true
 * @param  {number}  expiryDays  Expiry (in days from when the cookie is set). Pass 0 to create a session cookie. Default: 365 days
 * @param  {string}  path        URL path. Use this to set per-page cookies. Default: '/' (the whole site)
 * @param  {string}  domain      The host. No default. Set the domain to allow cookies across subdomains
 */
function setCookie( name, value = true, expiryDays = 365, path = '/', domain = '' )
{
	if ( !name )
	{
		return;
	}

	let setCookie = [
		name + '=' + value,
		'path=' + path || '/'
	];

	if ( expiryDays != 0 )
	{
        // Don't set the expiry if we don't have to.
        // Without this check, passing an expiry of 0 would expire the cookie immediately.
        // Instead, any "0" expiry cookies are cleared when the session ends.
		let expiryDate = new Date();

		expiryDate.setDate( expiryDate.getDate() + expiryDays );

		setCookie.push( 'expires=' + expiryDate.toUTCString() );
	}


	if ( domain )
	{
		setCookie.push( 'domain=' + domain );
	}

	document.cookie = setCookie.join( ';' );
}

/**
 * Delete a cookie
 *
 * Note that when deleting a cookie, you must match the path and domain of the originally created cookie
 *
 * @param  {string}  name    Cookie name
 * @param  {string}  path    URL path. Use this to set per-page cookies. Default: '/' (the whole site)
 * @param  {string}  domain  The host. No default. Set the domain to allow cookies across subdomains
 */
function deleteCookie( name, path = '/', domain = '' )
{
	if ( !name )
	{
		return;
	}

	let delCookie = [
		name + '=;',
		'expires=' + 'Thu, 01 Jan 1970 00:00:01 GMT', // set the cookie to have already expired
		'path=' + path || '/'
	];

	if ( domain )
	{
		delCookie.push( 'domain=' + domain );
	}

	document.cookie = delCookie.join( ';' );
}


/**
 * Checks if a browser/extension has enabled GPC privacy controls.
 *
 * @return  {bool}  True if enabled, false otherwise
 */
function isGPCPrivacyControlEnabled()
{
	// Bool check because `null` and `undefined` are possible values
	return ( window.navigator.globalPrivacyControl ) ? true : false;
}


// Exports
// ============================================================================

export const UCookies = { getCookie, setCookie, deleteCookie, isGPCPrivacyControlEnabled };
