/**
 * Standalone: GTAG Cookies (Google Tag Manager + OneTrust)
 *
 * Updates the datalayer when OneTrust cookie preferences are changed. These
 * preferences dictate which scripts are allowed to run in GTAG (which is set
 * up in GTAG itself).
 *
 * Enqueued in <head>, before any CMS scripts, via header-head.php
 *
 * Spoof a GDPR country with one of these query strings (via localised.php).
 * Note that the country code must be capitalised!
 *
 *   ?geoip_country_code=UK
 *   ?geoip_region=gdpr
 *
 * References:
 *   - https://www.simoahava.com/analytics/consent-mode-google-tags/
 *   - https://my.onetrust.com/articles/en_US/Knowledge/UUID-301b21c8-a73a-05e8-175a-36c9036728dc
 *   - https://developers.google.com/tag-platform/tag-manager/templates/consent-apis#consent_apis
 *
 * @link https://in-beta.codebasehq.com/projects/on24-support/tickets/868
 *
 * @module modules/SGTAGCookies
 * @since 868
 */


// Dependencies
// ============================================================================

import { UCookies } from '../common/utility/u-cookies';


// Main
// ============================================================================

class ON24GTagCookies
{
	constructor() {
		this.debugLog = false;
		this.hasFiredReadyEvent = false;
	}


	/**
	 * Methods to run on init
	 *
	 * @return  {void}
	 */
	init()
	{
		// Setup datalayer
		this.setupDatalayer();
		this.setupDatalayerListener();

		// Apply datalayer properties
		this.applyInitialDatalayerProps();

		// Fire custom event
		this.fireReadyEvent();
	}



	// Setup Datalayer
	// ============================================================================

	// Creates a listener for the datalayer, then runs a method when OneTrust
	// settings change. By default, OneTrust pushes stuff to the data layer when
	// its the user sets or chanegs their settings. But but it doesn't fire any
	// custom events or do anything else we could hook into, which is why we
	// need this extra code.

	// Ideally, OneTrust would just push the consent variables to the datalayer,
	// (eg. ad_storage, analytics_storage, etc), but as it doesn't do that,
	// we must do it on OneTrust's behalf.


	/**
	 * Setup the initial window.datalayer variable, with a proxy so we can
	 * listen for changes to it. We're doing this so that we can listen
	 *
	 * @return  {void}
	 */
	setupDatalayer()
	{
		// This would be the normal way of handling this. However, we want to attach
		// a proxy to this object, which will let us monitor it for changes.
		//// window.dataLayer = window.dataLayer || [];

		// Set up the proxy. Code via: https://stackoverflow.com/a/66968026
		// WARNING: This proxy will only work if window.datalayer does not exist yet!
		window.dataLayer = window.dataLayer || new Proxy([],
		{
			// This basically means: When the datalayer gets updated (ie. set), fire our custom event
			set: ( obj, prop, value ) =>
			{
				if ( prop !== 'length' )
				{
					const pushEvent = new CustomEvent( 'datalayerpush', {
						detail: value
					});

					window.dispatchEvent( pushEvent );
				}

				// `Return` ensures that any original stuff still happens as it
				// should, as though we weren't using this proxy
				return Reflect.set( obj, prop, value );
			}
		});
	}


	/**
	 * Setup the event listener for when the datalayer changes.
	 * Also fires the `updateOneTrustOptionsDatalayer` method when the OneTrust
	 * options are updated
	 *
	 * @return  {void}
	 */
	setupDatalayerListener()
	{
		window.addEventListener('datalayerpush', event =>
		{
			if ( this.debugLog )
			{
				// console.log('🍪🔼', `Value pushed to dataLayer: ${JSON.stringify(event.detail, null, 2)}`); // Version 1: full JSON string
				console.log( '🍪🔼', 'DataLayer Updated', event.detail ); // Version 2: simple object log
			}

			if ( event.detail.hasOwnProperty( 'event' ) )
			{
				// Added to datalayer when the user preferences are updated.
				// Also fires on initial load of the OneTrust banner (which happens after `OneTrustLoaded`), which sets the
				// `C0001` ("Necessary") option, which is always enabled.
				if ( event.detail.event === 'OneTrustGroupsUpdated' )
				{
					// Eg: ",C0001," (default setting on initial load, ie. allow "Necessary" cookies)
					// Eg: ",C0001,C0003,C0002,C0004," (allow all)
					const groupsStr = event.detail.OnetrustActiveGroups;
					let groupsArr = groupsStr.split( ',' );

					// Remove empty items, which are just ""
					groupsArr = ( groupsArr[0] === '' ) ? groupsArr.slice( 1 ) : groupsArr; // Remove first empty array item
					groupsArr = ( groupsArr[groupsArr.length - 1] === '' ) ? groupsArr.slice( 0, groupsArr.length - 1 ) : groupsArr;

					// Update the datalayer with the new consent options
					this.updateOneTrustOptionsDatalayer( groupsArr );

					// Try to fire the custom event that states that user opts have been saved.
					// Note that this method has a check to ensure that a certain cookie is present;
					// this cookie proves that the user has actually saved their prefs. This is
					// needed because the `OneTrustGroupsUpdated` event automatically fires when OneTrust
					// loads, regardless of whether the user updated their options manually or not
					this.fireReadyEvent();
				}
			}
		});
	}


	// Set Datalayer Properties
	// ============================================================================

	// Adds consent variables to the datalayer, so they can be noticed by GTAG.
	// Then, in GTM, tags can be set to only fire if these consent variables
	// have allowed it (in GTM this is under the tag, in Advanced Settings >
	// Consent).


	/**
	 * Apply GTAG datalayer settings. The datalayer is the set of data that GTAG
	 * monitors. In the case of the stuff we're dealing with in this file, it's
	 * monitoring for the presence of/changes to consent variables.
	 *
	 * @return  {void}
	 */
	apply_gtag()
	{
		window.dataLayer.push( arguments );
	}


	/**
	 * Adds a custom datalayer event, but only if the cookie that says that the
	 * user has saved their OneTrust preferences is present.
	 *
	 * With this, we can set a "Custom Event" trigger for tags in GTM, so they
	 * only fire after this event has been fired
	 *
	 * @return  {void}
	 */
	fireReadyEvent()
	{
		if ( this.hasFiredReadyEvent )
		{
			return;
		}

		if ( !window.on24_geoip.is_gdpr_region || UCookies.getCookie( 'OptanonAlertBoxClosed' ) )
		{
			if ( this.debugLog )
			{
				console.log( '🍪🚀', 'Firing custom event: "on24_onetrust_optionssaved"' );
			}

			window.dataLayer.push( { 'event': 'on24_onetrust_optionssaved' } );

			this.hasFiredReadyEvent = true;
		}
	}


	/**
	 * Sets up the initial datalayer consent variables.
	 *
	 * For non-GDPR regions, all consent options are granted. Whereas for GDPR
	 * regions, most are denied until the user accepts them in OneTrust (or has
	 * already accepted them, and has a cookie to prove it)
	 *
	 * @return  {void}
	 */
	applyInitialDatalayerProps()
	{
		let defaultsSettings = {
			wait_for_update: 500,
		};

		let customSettings = {};

		// Via localized JS in: library/localised.php
		if ( window.on24_geoip.is_gdpr_region )
		{
			if ( this.debugLog )
			{
				console.log( '🍪🌍', 'GDPR REGION. Getting default settings from cookie' );
			}

			// GDPR regions. Denies everything, until the user manually approves things
			customSettings = this.getOneTrustSettingsFromCookie();
		}
		else
		{
			if ( this.debugLog )
			{
				console.log( '🍪🌍', 'Not a GDPR region. Granting all permissions' );
			}

			// Non-GDPR regions. Allows everything, because non-GDPR regions don't have
			// cookie options (ie. OneTrust) to allow things manually
			customSettings = {
				ad_storage:              'granted',
				analytics_storage:       'granted',
				functionality_storage:   'granted',
				personalization_storage: 'granted',
				security_storage:        'granted',
			};
		}

		Object.assign( defaultsSettings, customSettings );

		this.apply_gtag( 'consent', 'default', defaultsSettings );
	}


	/**
	 * Get the user's current OneTrust settings, from the cookie. If no cookie
	 * exists, returns the default settings which deny all cookies that can be
	 * allowed/denied via OneTrust.
	 *
	 * @return  {object}  Object of consent settings
	 */
	getOneTrustSettingsFromCookie()
	{
		let otCookieFull = UCookies.getCookie( 'OptanonConsent' );
		let settings = {};

		// No cookie means return defaults
		if ( !otCookieFull )
		{
			settings = {
				ad_storage:              'denied',  // C0004 = Advertising - Enables storage (such as cookies) related to advertising (Supported by OneTrust)
				analytics_storage:       'denied',  // C0002 = Performance - Enables storage (such as cookies) related to analytics, e.g., visit duration (Supported by OneTrust)
				functionality_storage:   'denied',  // C0003 = Functional  - Enables storage that supports the functionality of the website or app, e.g., language settings
				personalization_storage: 'granted', // Enables storage related to personalization, e.g., video recommendations
				security_storage:        'granted', // Enables storage related to security such as authentication functionality, fraud prevention, and other user protection

				// Region-specific stuff. We could do this, but we're using `on24_geoip.is_gdpr_region` instead to make this clearer in the code
				//// region: window.on24_geoip.gdpr_country_codes, // eg: ['US-CA', 'FI']
			};

			if ( this.debugLog )
			{
				console.log( '🍪🔶', 'No OneTrust cookie exists yet, using default GDPR settings:', settings );
			}

			return settings;
		}

		// Decode (converts eg. "C0001%3A1%2CC0003%3A1%2CC0002%3A1%2CC0004%3A1" to "C0001:1,C0003:1,C0002:1,C0004:1")
		otCookieFull = decodeURIComponent( otCookieFull );

		// Break cookie down into the parts we want
		// Example:
		//   isGpcEnabled=0&datestamp=Mon+Oct+16+2023+19:24:45+GMT+0100+(British+Summer+Time)&version=202305.1.0&browserGpcFlag=0&isIABGlobal=false&hosts=&consentId=4b3d9c39-526f-4eea-add0-4b9667719ad5&interactionCount=1&landingPath=NotLandingPage&groups=C0001:1,C0003:1,C0002:1,C0004:1
		// We need this part:
		//   &groups=C0001:1,C0003:1,C0002:1,C0004:1
		const otCookieArr = otCookieFull.split( '&' );

		// Get the group settings
		let settingsStr   = '';
		settingsStr       = otCookieArr.filter( ( val ) => val.startsWith( 'groups=' ) )[0];
		settingsStr       = settingsStr.replace( 'groups=', '' );
		const settingsArr = settingsStr.split( ',' ); // eg: ['C0001:1', 'C0003:1', 'C0002:1', 'C0004:1']

		if ( this.debugLog )
		{
			console.log( '🍪🔶', 'OneTrust Cookie - Full Array:', otCookieArr );
			console.log( '🍪🔶', 'OneTrust Cookie - Consent:', settingsArr );
		}

		settings = {
			ad_storage:              settingsArr.includes( 'C0004:1' ) ? 'granted' : 'denied',  // C0004 = Advertising ("C0004:0" would mean denied)
			analytics_storage:       settingsArr.includes( 'C0002:1' ) ? 'granted' : 'denied',  // C0002 = Performance
			functionality_storage:   settingsArr.includes( 'C0003:1' ) ? 'granted' : 'denied',  // C0003 = Functional

			// Not managed by OneTrust
			personalization_storage: 'granted',
			security_storage:        'granted',
		};

		if ( this.debugLog )
		{
			console.log( '🍪🔶', 'Options from cookie:', settings );
		}

		return settings;
	}


	/**
	 * Update the datalayer when the user updates their OneTrust options.
	 * Triggered in the listener for our custom `datalayerpush` event.
	 *
	 * Groups:
	 *   C0001 = Necessary    - N/A
	 *   C0002 = Performance  - analytics_storage
	 *   C0003 = Functional   - functionality_storage
	 *   C0004 = Advertising  - ad_storage
	 *   C0005 = Social Media - N/A
	 *   https://app-eu.onetrust.com/cookies/categorizations?tab=Categories
	 *
	 * @param   {Array}  groupsArr  Array of OneTrust cookie preference groups
	 *
	 * @return  {void}
	 */
	updateOneTrustOptionsDatalayer( groupsArr )
	{
		const newSettings = {
			ad_storage:              groupsArr.includes( 'C0004' ) ? 'granted' : 'denied',  // C0004 = Advertising
			analytics_storage:       groupsArr.includes( 'C0002' ) ? 'granted' : 'denied',  // C0002 = Performance
			functionality_storage:   groupsArr.includes( 'C0003' ) ? 'granted' : 'denied',  // C0003 = Functional

			// Not managed by OneTrust
			personalization_storage: 'granted',
			security_storage:        'granted',
		};

		if ( this.debugLog )
		{
			console.log( '🍪💾🅰', 'Updating datalayer with OneTrust preferences:', { groupsArr, newSettings } );
		}

		this.apply_gtag( 'consent', 'update', newSettings);

		if ( this.debugLog )
		{
			console.log( '🍪💾🅱', 'Finished updating datalayer' );
		}
	}
};


// Init
// ============================================================================

const ON24GTagCookiesInstance = new ON24GTagCookies();
ON24GTagCookiesInstance.init();
